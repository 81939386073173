import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import QrCodeRenderer from './RenderQrCode';
import { useSelector } from 'react-redux';
import handleExportPDF from '../Utils/PDFExporter';
import NoData from './NoData';
import MainSideBar from './SideBar';

export default function LinkCard() {
    const qrCodePrintableID = "qrCodePrintableIDComponent"
    const places = useSelector(state=> state.place)
    const branchId = useSelector(state=> state.place)[0].id
    let ReviewUrl = window.location.href
    ReviewUrl = ReviewUrl.split("/")
    ReviewUrl[3] = `client/${branchId}`
    const newUrl = ReviewUrl.join("/")
    const today = new Date();
    const formatted = today.getFullYear();

  const printCard = () =>{
    handleExportPDF(qrCodePrintableID)
  }

  return (
    <MainSideBar>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"  }}>
        {
        Boolean(places[0]) ? 
          
          <Grid container spacing={2} style={{margin: "0 auto"}}>
          <Grid id={qrCodePrintableID} item xs={12}>
            <Card sx={{ width: 500 }}> {/* Set card width */}
              <CardContent style={{ textAlign: "center" }}>  {/* Center content within card */}
                <Typography variant="h4" component="div">
                  {places[0].displayName}
                </Typography>
                <Typography color="text.secondary">
                  {places[0].formattedAddress}
                </Typography>
                <QrCodeRenderer data={newUrl} />
                <Typography color="text.secondary">
                  Scan the QR code above to share your feedback.
                </Typography>
                <Typography color="text.secondary">
                  or use the below link
                </Typography>
                <Typography color="text.secondary">
                  <a href={newUrl} target='_blank'>{newUrl}</a>
                </Typography>
                <Typography color="text.secondary">
                  Nexus Systems.<br></br>
                  &copy;{formatted}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>  {/* Occupy full width on small screens  */}
            <Button variant="contained" onClick={printCard}>Print</Button>
          </Grid>
        </Grid>
        :
        <NoData message={<><h5>No Branches is Allocated.<br/><b style={{ color:"red" }}>Can Not print Link.</b></h5></>}/>
      }
      </div>
    </MainSideBar>
  );
}
