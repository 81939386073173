import React, { useState, useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import './App.css';

const ApexLineChart = ({ reviewsForLineGraph, sentimentsForLineGraph }) => {
  // Extract review data (dates and ratings)
  const reviewDates = reviewsForLineGraph.map(review => review.published_date.split('T')[0]);
  const reviewRatings = reviewsForLineGraph.map(review => review.rating);

  // Extract dynamic sentiment data
  const allSentiments = Object.keys(sentimentsForLineGraph);
  
  const sentimentData = allSentiments.map(sentiment => {
    const sentimentEntries = sentimentsForLineGraph[sentiment];
    
    // Map sentiment data to review dates, or return null if no data exists for that date
    const sentimentRatings = reviewDates.map(date => {
      const foundEntry = sentimentEntries.find(entry => entry.published_date.split('T')[0] === date);
      return foundEntry ? foundEntry.value : null;  // If no sentiment entry for the date, return null
    });

    return {
      name: `${sentiment} Ratings`,  // Name of the series will be dynamic
      data: sentimentRatings,
    };
  });

  // Combine user ratings with the dynamic sentiment data
  const allSeries = [
    {
      name: 'User Ratings',
      data: reviewRatings,
    },
    ...sentimentData,  // Add all dynamic sentiment series here
  ];

  const chartRef = useRef(null);

  // Chart options
  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    xaxis: {
      categories: reviewDates,  // Use review dates for X-axis
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Ratings',
      },
    },
    tooltip: {
      shared: false,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        const value = series[seriesIndex][dataPointIndex];
        const description = `${allSeries[seriesIndex].name} - Date: ${reviewDates[dataPointIndex]}`;
        return `<div class="tooltip-content">
                  <span>Rating: ${value}</span><br/>
                  <span>${description}</span>
                </div>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
  };

  useEffect(() => {
    const handleWheel = _.throttle((event) => {
      event.preventDefault();

      const chart = chartRef.current.chart;
      const chartRect = chart.w.globals.dom.baseEl.getBoundingClientRect();
      const mouseX = (event.clientX - chartRect.left) / chartRect.width;

      const currentMinX = chart.w.globals.minX;
      const currentMaxX = chart.w.globals.maxX;
      const totalX = currentMaxX - currentMinX;

      const zoomFactorIn = 0.1;
      const zoomFactorOut = 0.9;
      let zoomRange;

      let newMinX, newMaxX;
      if (event.deltaY < 0) {
        zoomRange = zoomFactorIn * totalX;
        const midPoint = currentMinX + mouseX * totalX;
        newMinX = midPoint - zoomRange / 2;
        newMaxX = midPoint + zoomRange / 2;
      } else {
        zoomRange = zoomFactorOut * totalX;
        newMinX = currentMinX - zoomRange / 2;
        newMaxX = currentMaxX + zoomRange / 2;
      }

      newMinX = Math.max(newMinX, chart.w.globals.initialMinX);
      newMaxX = Math.min(newMaxX, chart.w.globals.initialMaxX);

      if (!isNaN(newMinX) && !isNaN(newMaxX) && newMinX < newMaxX) {
        chart.zoomX(newMinX, newMaxX);
      }
    }, 100);

    const chartElement = chartRef.current?.chart?.w.globals.dom.baseEl;

    if (chartElement) {
      chartElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (chartElement) {
        chartElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <div className="chart-container" style={{ width: '1500px', height: '550px' }}>
      <Chart ref={chartRef} options={options} series={allSeries} type="line" height="100%" width="1200px" />
    </div>
  );
};

export default ApexLineChart;
