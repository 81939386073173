// import { Box, Grid } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { apiSlice, useGetNexusAnaysisQuery } from "../../Services/ApiServices";
// import { addGoogleData } from "../../App/features/googleDataSlice";
// import LoadingSpinner from "../Messages/LoadingSpinner";
// import { useEffect, useState } from "react";
// import ChartPage from './LineGraph'; // Assuming LineGraph can handle both line and donut charts
// import ApexDonutChartWithDetails from './ApexDonutChartWithDetails'; // Import the donut chart component

// export default function NexusData() {
//   const dispatch = useDispatch();
//   const hotelId = useSelector(state => state.detail.id);
//   const nexusState = useSelector(state => state.googleData);
//   const [loading, setLoading] = useState(true);
//   const [err, setErr] = useState({ error: false, message: "" });
//   const { refetch, data, isLoading, isSuccess, isError, error } = useGetNexusAnaysisQuery(hotelId);

//   const updater = async () => {
//     dispatch(apiSlice.util.resetApiState());
//     if (hotelId) {
//       const resp = await refetch({ forceRefetch: true }).then((response) => {
//         setLoading(false);
//         if (response.isError) {
//           setErr({ error: true, message: response.error.message });
//         }
//         if (response.data) {
//           dispatch(addGoogleData(response.data.data));
//         }
//       });
//     }
//   };

//   useEffect(() => {
//     updater();
//   }, [hotelId]); // Add hotelId to dependency array to refetch when it changes

//   // Extract data for charts
//   const reviewsForLineGraph = nexusState?.reviewsForLineGraph || [];
//   const sentiments = nexusState?.sentiments || {};

//   // Prepare data for the donut chart
//   const sentimentData = {
//     "Food": sentiments?.Food || 0,
//     "Location": sentiments?.Location || 0,
//     "Room": sentiments?.Room || 0,
//     "Environment": sentiments?.Environment || 0
//   };

//   console.log('Sentiment Data:', sentimentData); // Debugging output

//   return (
//     <Box width={"100%"}>
//       {loading ? (
//         <LoadingSpinner />
//       ) : (
//         <Grid container spacing={2}>
//           <Grid item xs={12} xl={12} lg={12} md={12}>
//             <ChartPage reviewsForLineGraph={reviewsForLineGraph} />
//           </Grid>
//           <Grid item xs={12} xl={12} lg={12} md={12}>
//             <ApexDonutChartWithDetails sentiments={sentimentData} />
//           </Grid>
//         </Grid>
//       )}
//     </Box>
//   );
// }




import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetNexusAnaysisQuery } from "../../Services/ApiServices";
import { addGoogleData } from "../../App/features/googleDataSlice";
import LoadingSpinner from "../Messages/LoadingSpinner";
import { useEffect, useState } from "react";
import ChartPage from './LineGraph'; // Assuming LineGraph can handle both line and donut charts
import ApexDonutChartWithDetails from './ApexDonutChartWithDetails'; // Import the donut chart component

export default function NexusData() {
  const dispatch = useDispatch();
  const hotelId = useSelector(state => state.detail.id);
  const nexusState = useSelector(state => state.googleData);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState({ error: false, message: "" });
  const { refetch } = useGetNexusAnaysisQuery(hotelId);

  const updater = async () => {
    dispatch(apiSlice.util.resetApiState());
    if (hotelId) {
      try {
        const response = await refetch({ forceRefetch: true });
        if (response.isError) {
          setErr({ error: true, message: response.error.message });
        }
        if (response.data) {
          dispatch(addGoogleData(response.data.data));
        }
      } catch (error) {
        setErr({ error: true, message: error.message });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    updater();
  }, [hotelId]); // Add hotelId to dependency array to refetch when it changes

  // Extract data for charts
  const reviewsForLineGraph = nexusState?.reviewsForLineGraph || [];
  const sentiments = nexusState?.sentiments || {};
  const sentimentsForLineGraph = nexusState?.sentimentsForLineGraph || {};

  // Prepare dynamic data for the donut chart
  const sentimentData = Object.keys(sentiments).reduce((acc, key) => {
    acc[key] = sentiments[key] || 0;
    return acc;
  }, {});

  console.log('Sentiment Data:', sentimentData); // Debugging output

  return (
    <Box width={"100%"}>
      {loading ? (
        <LoadingSpinner />
      ) : err.error ? (
        <div>Error: {err.message}</div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <ChartPage reviewsForLineGraph={reviewsForLineGraph} sentimentsForLineGraph={sentimentsForLineGraph} />
          </Grid>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <ApexDonutChartWithDetails sentiments={sentimentData} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
