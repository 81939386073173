import { Box, Button, Grid, Paper, Drawer, Typography, FormControlLabel, Checkbox, Divider } from "@mui/material";
import MainSideBar from "../../../Components/SideBar";
import { apiSlice, useAssignSenimentTenantMutation, useGetSentimentsQuery, useGetTenantTypeQuery, useRemoveSenimentTenantMutation } from "../../../Services/ApiServices";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import DataGridTable from "../../../Components/Datagrid";
import ViewSentiment from "./ViewSentiment";

function EditorComponent({mainData, updater}) {
  const [mutuate, {data: dataResponse, isLoading: ldd, isError: eerx, error: errr}] = useAssignSenimentTenantMutation()
  const [mutuaterm , {isSuccess, isLoading: rmload}] = useRemoveSenimentTenantMutation()
  const dispatch = useDispatch()
  const [tenantList, setTenantType] = useState([])
  const [tempList, setTempList] = useState([])
  const [err, setErr] = useState({})
  const { data, refetch, isLoading, error } = useGetTenantTypeQuery()
    const loadTenantType = async () =>{
     dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        setTempList((prev) =>{
          return response.data.data.tenant_types.map((item)=>{
              return {
            id: item.id,
            title: item.title,
            actions: item
        }
          })
        })
      }
      
    })
  }
   const assignTenant = (e) => {
    e.preventDefault()
    if(e.target.checked) {
      setTenantType((prev) =>{
        return [...prev, {sentimentId: mainData.id, tenantTypeId: e.target.value }]
      })
    }
    else {
      setTenantType((prev) =>{
        return prev.filter((item) => item.tenantTypeId != e.target.value)
      })
    }
    console.log("Choosen ", tenantList)
  }
  const deleteType = async (title ,id, sentId) =>{
    const conf = window.confirm(`Are you sure Do you want to delete ${title}?`)
    if(conf) {
      await mutuaterm({values: [{sentimentId: sentId, tenantTypeId: id}]}).then((reponse) =>{
       if(isSuccess) {
        updater()
       }
      })
    }
  }

  const assignTenantType = async () => {
    if(tenantList.length == 0) {
      alert("Please select at least one tenant type.")
    }
    else {
      await mutuate({values: tenantList}).then((response) =>{
        console.log("Response for ", response);
        updater()
      })

    }
  }



  useEffect(()=>{
  loadTenantType()
  }, [mainData])

  return (
    <>
      {
        !isLoading && <>
        <Paper sx={{ padding:"2%" }}>
        <table>
          <tr>
            <td style={{ width:"30%" }}><Typography variant="h6">Title</Typography></td>
            <td>{mainData.title}</td>
          </tr>
          <tr>
            <td style={{ width:"30%" }}><Typography variant="h6">Prompt</Typography></td>
            <td>{mainData.prompt}</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign:"center" }}><Typography variant="h6">Tenant Types</Typography></td>
          </tr>
          </table>
          <table>
            {
              (mainData.tenant_types?.length > 0) && 
              mainData.tenant_types?.map((item) =>{
                return <tr>
                <td style={{ width:"70%" }}><Typography variant="h6">{item.title}</Typography></td>
                <td><Button variant="contained" color="error" onClick={() =>{
                  deleteType(item.title, item.id, mainData.id)
                  updater()
                }} >Delete</Button></td>
                </tr>
              })
            }
        </table>
        </Paper>
        <Paper sx={{ padding:"2%" }}>

        <Divider/>
        <Typography variant="h5">Assign Tenant</Typography>
        <Divider/>
        {
          tempList.map((item) => {
            return <FormControlLabel label={item.title} value={item.id} onChange={assignTenant} control={<Checkbox />} />
          })
        }
        <Divider/>
        <Button variant='contained' type='submit' color='warning' onClick={assignTenantType} >Assign type</Button>  
        </Paper>
        </>
      }
    </>
  )


}



export default function ManageTenantType () {
    const { data, refetch, isLoading, error } = useGetSentimentsQuery()
    const dispatch = useDispatch()
    const [openDetail, setOpenDetail] = useState(false)
    const [tempList, setTempList] = useState([])
    const [sentiData, setSentiData] = useState({})
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [tenantTypes, setTenantTypes] = useState([])
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        setTenantTypes((prev) => { 
            return response.data.data.sentiments.map((item) => {
                return {
                    id: item.id,
                    title: item.title,
                    actions: item
                } 
            })
        })
        setLoading((prev) =>{ return false})
      }
    })
  }






  useEffect(()=>{
    updater()
  }, [])


  const columns = [
    {
    field: 'title',
    headerName: 'Title',
    width: 200,
    editable: false,
   },   
  {
    field: 'actions',
    headerName: 'Actions',
    width: 500,
    editable: false,
    renderCell: (params) => {
            return (
                <Box>
                    <Button sx={{ marginRight:"2%" }} color='success' variant="outlined" onClick={(e)=>
                    {
                      setSentiData((prev)=>{return params.formattedValue});
                      setOpenDetail((prev)=>{return true});
                    }}>View</Button>
                    <Button color='warning' variant="outlined" onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}>Manage Type</Button>
                </Box>
            )}
          },  
        ];


    return (
        <MainSideBar>
            {loading ? <FetchLoading message="Loading" /> : 
            <>
            <Grid sx={{ width:"100%" }} container>
                <Grid sx={{ width:"98%" }} Item xs={12} lg={6} sm={12} md={6}>          
                        <ViewSentiment open={openDetail} data={sentiData} closer={setOpenDetail} />
                        <DataGridTable title={"Sentiments"} columns={columns} data={tenantTypes} />
                </Grid>
                <Grid sx={{ width:"98%" }} Item xs={12} lg={6} sm={12} md={6}>
                    <Paper sx={{ }}>
                      
                        {
                            openEdit && <EditorComponent updater={updater}  mainData={editData} />
                        }
                    </Paper>
                </Grid>
            </Grid>
            </>}
        </MainSideBar>
    )

}